import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import PostPreview from "../components/postPreview"
import ArchivePagination from "../components/archivePagination"

import '../assets/Scss/templates/archives.scss'

const Archive = props => {
  const [isFirstPage, setisFirstPage] = useState(false)
  const {
    data: {
      allWpPost: { nodes, pageInfo },
    },
    pageContext: { archivePath, uri },
  } = props

  useEffect(() => {
    if(pageInfo.currentPage === 1){
      setisFirstPage(true)
    } else {
      setisFirstPage(false)
    }
  }, [isFirstPage, pageInfo.currentPage])

  return (
    <Layout bodyClass="articles blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <Seo
        title="Blog"
        description="Arab Women Rights Advocats Blog!"
        uri={uri}
      />

      <section className={isFirstPage ? "container -columns is-multiline firstPage" : "container -columns is-multiline"}>
        {nodes &&
          nodes.map((post, index) => {
            return (
              <div className="-column" key={index}>
                <PostPreview
                  key={index}
                  post={post}
                  isLast={index === nodes.length - 1}
                />
              </div>
            )
          })}
      </section>

      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        languages: { nodes: { elemMatch: { name: { eq: "English" } } } }
        author: { node: { databaseId: { eq: $userDatabaseId } } }
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default Archive
